import Ajv from 'ajv';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { ajv } from '../../config-ajv/ajv';
import { FormError } from '../../types';
import { filterOutErrorsByKeywords } from '../filter-out-errors-by-keywords/filter-out-errors-by-keywords.util';

interface ValidateDataProps {
  data: any;
  schema: AnnotatedJsonSchema;
  filteredErrorKeywords?: string[];
  ajv?: Ajv;
}
/**
 * Validates data against a JSON schema using AJV adding a `propertyName` based on label annotation to each error. It takes in an the schema and data, and an optional array of keywords to filter out of the errors.
 *
 * @param props.schema - The JSON schema to validate against
 * @param props.data - The data to validate
 * @param props.ajv - Ajv instance to override our default one that is used
 * @param props.filteredErrorKeywords - Array of error keywords from AJV. Errors with these keywords will be filtered out of the returned errors
 * @returns An object containing any validation errors (`errors`)
 */

export const validateData = ({
  data,
  schema,
  filteredErrorKeywords,
  ajv: customAjv,
}: ValidateDataProps): {
  formErrors: FormError[];
} => {
  const ajvInstance = customAjv ?? ajv;
  const validate = ajvInstance.compile(schema);
  validate(data);

  if (validate.errors) {
    return {
      formErrors: filterOutErrorsByKeywords({
        errors: validate.errors,
        filteredErrorKeywords,
      }).map((error) => ({
        ...error,
        propertyName: error.parentSchema?.['x-entity-label']?.key,
      })),
    };
  }

  return { formErrors: [] };
};
