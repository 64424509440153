/**
 * These are the error keyword types in JSON Schema that we want to filter out on draft entities.
 *
 * This is because these errors are typically not relevant to the author when they are still drafting their content.
 *  - "required" : not be helpful if the author has not yet had a chance to fill out all the required fields.
 *  - "minItems" : array keyword, want to allow drafting empty array types
 *  - "minProperties" : object keyword, want to allow drafting empty objects
 *  - "minContains": array keyword, used to validate that an array contains at least a minimum number of items that match a given schema.
 *  - "minLength": string keyword, during drafting an author might be in the process of writing and hasn't yet reached the required length.
 *  - "uniqueItems": array keyword, ensures all items in an array are unique. An author might not have had a chance to make everything unique yet.
 *
 * Read about these keywords here: https://json-schema.org/draft/2020-12/draft-bhutton-json-schema-validation-00#general
 */
export const draftErrorFilterKeywords = [
  'required',
  'minItems',
  'minProperties',
  'minContains',
  'minLength',
  'uniqueItems',
];
