import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { getSchemaFromEntries } from '../get-schema-from-entries/get-schema-from-entries';

/**
 * Alters a schema of type `object` by only including properties for which the passed
 * `filterProperties` function returns true and performs the mutateProperties function on each property schema.
 *
 * @param schema current schema
 * @param filterProperties a function to filter stuff out
 * @param mutateProperties a function that does some mutation on the current property schema
 *
 * @return the altered properties schema after running the
 */

export interface GetSchemaWithMutatedPropertiesArgs {
  schema: AnnotatedJsonSchema;
  filterProperties?: (
    schema: AnnotatedJsonSchema,
    propertyName?: string,
  ) => boolean;
  mutateProperties?: (
    schema: Partial<AnnotatedJsonSchema>,
  ) => AnnotatedJsonSchema;
}
export const getSchemaWithMutatedProperties = ({
  schema,
  filterProperties = () => true,
  mutateProperties,
}: GetSchemaWithMutatedPropertiesArgs) => {
  if (schema.type !== 'object') {
    return schema;
  }

  const schemaEntries = Object.entries(schema.properties ?? {});
  const recalculatedProperties = getSchemaFromEntries(
    schemaEntries
      .filter(([propertyName, propertySchema]) =>
        filterProperties(propertySchema, propertyName),
      )
      .map(([propertyName, propertySchema]) => {
        const mutatedSchema =
          mutateProperties?.(propertySchema) ?? propertySchema;
        return [propertyName, mutatedSchema] as [string, AnnotatedJsonSchema];
      }),
  );

  return {
    ...schema,
    properties: recalculatedProperties,
  } as AnnotatedJsonSchema;
};
